/* SubscriptionInfo.css */

.subscription-section {
    padding: 40px;
    background-color: #f5fafd;
    display: flex;
    justify-content: center;
  }
  
  .subscription-container {
    display: flex;
    gap: 20px;
    background-color: #eaf3f7;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    width: 100%;
  }
  
  .plan-card {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    width: 250px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .plan-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: #003366;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 2rem;
    color: #0099ff;
    margin-bottom: 20px;
  }
  
  .price-duration {
    font-size: 1rem;
    color: #666;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-features li {
    font-size: 1rem;
    color: #0099ff;
    margin: 10px 0;
  }
  
  .change-plan-btn {
    background-color: #64BAE2;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
  }
  
  .change-plan-btn:hover, .cancel_subscription:hover {
    background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);
    color: #ffffff;
  }
  
  .subscription-info {
    flex-grow: 1;
    padding: 20px 30px;
  }
  
  .subscription-title {
    font-size: 1.8rem;
    color: #003366;
    margin-bottom: 30px;
  }
  
  .subscription-title .highlight {
    color: #0099ff;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .info-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .info-label {
    font-size: 1rem;
    color: #003366;
    font-weight: 500;
  }
  
  .info-value {
    font-size: 1rem;
    color: #666;
  }

  .cancel_subscription{
    background-color: #ffffff;
    color: #64bae2;
    border-color: #64bae2;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 1rem;
  }


  
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .subscription-container {
      flex-direction: column;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  
    .plan-card {
      margin-bottom: 20px;
    }
  
    .subscription-info {
      padding: 10px 15px;
    }
  }
  