.testimonials-section {
    position: relative;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    background-color: #ffffff; /* Optional light background color */
    background-image: url('./testimonialBG.png');
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the background image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
  }
  
.testimonial-card {
    display: flex;
    justify-content: flex-end;
  }
  
.testimonial-card.reverse {
  
    flex-direction: row-reverse; /* Reverse the order for alternating cards */
    margin-top: -30px;
  }
  
.testimonial-quote {
    margin-right: 15px;
    margin-left: 15px;
    width: 48%;
    text-align: left;
  }
  
/* .testimonial-quote p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
  } */
  
.quote-icon {
    font-size: 2rem;
    color: #0099ff;
    margin-right: 15px;
  }

.quote-main{
    display: flex;
  }
  
.author-name {
  color: #001b34;
  text-align: left;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 30px;
  line-height: 100%;
  font-weight: 500;
  }
  
.author-title {
  color: #001b34;
  text-align: left;
  font-family: "HelveticaNeue-Regular", sans-serif;
  font-size: 20px;
  line-height: 100%;
  font-weight: 400;
  }
  
.testimonial-image {
    display: flex;
    width: 45%;
    justify-content:flex-start;
  }
  
.testimonial-image img {
    width: 50%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  
  /* Section Title */
.section-title {
  color: rgba(100, 186, 226, 0.1);
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 80px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 0px;
  -webkit-text-stroke: 1px var(--colorprimary, #64bae2);
  }

.FirstImg{    
    width: 25%;
  }
  
.mobile-testimonial-card{
    display: none;
  }

.testimonialDetails{
  color: rgba(0, 27, 52, 0.75);
  font-family: "HelveticaNeue-Regular", sans-serif;
  font-size: 20px;
  align-self: stretch;
  font-size: 20px;
  line-height: 25px;
}

.section-title_mobile{
  display: none;
}
  /* Responsive Design for Mobile */
  @media screen and (max-width: 768px) {
    
    .section-title_mobile{
      display: block;
      color: rgba(100, 186, 226, 0.1);
      text-align: center;
      font-family: "HelveticaNeue-Medium", sans-serif;
      font-size: 58px;
      line-height: 100%;
      font-weight: 500;
      position: relative;
      -webkit-text-stroke: 1px transparent;
    }

    .quote-main{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .testimonials-section{
      padding: 10px;
    }
    .mobile-quote-main{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .testimonial-card {
      flex-direction: column;
      text-align: center;
      margin-top: 100px;
    }
    .mobile-testimonial-card{
      flex-direction: column;
      text-align: center;
      display: block;
    }
    .testimonial-quote, .testimonial-image ,.mobile-testimonial-quote, .mobile-testimonial-image {
      width: 100%;
    }

    .br-style-none-mobile{
      display: none;
    }

    .mbl_text_align{
      text-align: center;
    }

    .author-name{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    .testimonialDetails{
      color: rgba(0, 27, 52, 0.75);
      text-align: center;
      font-family: "HelveticaNeue-Regular", sans-serif;
      font-size: 16px;
      line-height: 140%;
      font-weight: 400;
      position: relative;
      align-self: stretch;
    }
  
    .testimonial-quote, .mobile-testimonial-quote {
      margin-bottom: 20px;
      margin-right: 0px;
      margin-left: 0px;
      text-align: left;
    }
  
    .testimonial-image img {
      width: 60%;
    }

    .mobile-testimonial-image img {
      width: 60%;
    }
  
    .section-title {
      display: none;
    }

    .testimonial-card.reverse {
      display: none;
    }
    .quote-main.second{
      width: 100%;
    }
    .FirstImg{
      width: 80%;
      margin-left: 30px;
    }

    .section-title{
      display: none;
    }
  }
  