/* Features.css */

.features_and_benefites{
    color: #6ABAE2;
    margin-top: 40px;
    margin-bottom: 40px;
}
.features-container-main{
    align-items: center;
    text-align: center;
    padding: 30px;
    padding-top: 50px;
    background-color: #EAF5FB ;
}
.features-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 22%;
  height: 200px;
  cursor: pointer;
  text-align: left; /* Ensure everything is left-aligned */
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-icon {
  font-size: 40px;
  margin-bottom: 15px;
  text-align: left; /* Make sure the icon is left-aligned */
}

.feature-content {
  text-align: left; /* Ensure text content is left-aligned */
}

.workflow-description_txt{
  padding: 0px;
  margin-top: 10px;
}
.feature-title {
  color: #001b34;
  text-align: left;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 1.4vw;
  line-height: 100%;
  font-weight: 700;
  min-height: 1px;
  padding: 0px;
  margin: 0px;
}

.feature-description {
  color: rgba(0, 27, 52, 0.75);
  text-align: left;
  font-family: "HelveticaNeue-Regular", sans-serif;
  font-size: 17px;
  line-height: 140%;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}

/* Media query for mobile devices (max-width: 768px) */
@media screen and (max-width: 768px) {
    .features-container {
      flex-direction: column; /* Stack the items vertically */
      gap: 10px; /* Adjust spacing between cards */
    }

    .feature-details{
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    .features-container-main{
      padding: 15px;
    }
    
  
    .feature-card {
      width: 100%; /* Make each card take full width on mobile */
      margin-bottom: 20px; /* Add some space between cards */
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  
    .feature-icon {
      font-size: 30px; /* Reduce icon size on mobile */
    }
  
    .feature-title {
      font-size: 20px; /* Reduce title font size on mobile */
    }
  
    .feature-description {
      font-size: 16px; /* Adjust description font size on mobile */
      margin: 0;
      text-align: center;
      font-family: "HelveticaNeue-Regular", sans-serif;
    }
    .company-logos{
      gap:0px;
    } 

    .cta-container{
      display: none;
    }
  }
  
  /* Media query for very small devices (max-width: 480px) */
  @media screen and (max-width: 480px) {
    .feature-card {
      padding: 15px; /* Reduce padding for very small devices */
    }
  
    .feature-icon {
      font-size: 24px; /* Further reduce icon size */
    }
  
    .feature-title {
      font-size: 20px; /* Further reduce title font size */
    }
  
    .feature-description {
      font-size: 16px; /* Further reduce description font size */
      margin: 0;
      text-align: center;
      font-family: "HelveticaNeue-Regular", sans-serif;
    }
  }
