header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px 20px;
    position: relative;
}

.logo {
    display: flex;
    align-items: center;
    margin-right: auto; /* Aligns logo to the left */
}

.logo img {
    height: 52px;
    width: 190px;
}

.nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.nav-links ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 50px;
}

.nav-links a {
    text-decoration: none;
    color: #001b34;
    text-align: left;
    font-family: var(
      --button-desktop-font-family,
      "HelveticaNeue-Medium",
      sans-serif
    );
    font-weight: bold;
    transition: color 0.3s;
    font-size: 16px;
}

.nav-links a:hover {
    color: #EC7324;
}


.nav-links li {
    margin-right: 40px;
    cursor: pointer;

}

.get-formatter-container {
    margin-left: auto; /* Aligns the button to the right */
}

.dropdown {
    position: relative;
  }
  
  .dropdown-btn {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .dropdown-btn i {
    margin-left: 10px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 150px;
    z-index: 10;
  }
  
  .dropdown-item {
    text-decoration: none;
    color: #001b34;
    text-align: left;
    font-family: var(
      --button-desktop-font-family,
      "HelveticaNeue-Medium",
      sans-serif
    );
    font-weight: bold;
    transition: color 0.3s;
    display: block;
    padding: 10px;
  }
  
  .dropdown-item:hover {
    background-color: #f4f4f4;
    color: #007bff;
  }
  
  /* Close the dropdown when clicking outside (optional) */
  .dropdown-menu a:hover {
    background-color: #eee;
  }
  
.get-formatter-btn {
    padding: 10px 20px;
    border: none;
    background-color: #6ABAE2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.get-formatter-btn:hover {
    background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);
}

.menu-icon{
    display: none;
}

.mobile_li{
    display: none;
}
/* Mobile Styles */
@media (max-width: 1040px) {
    .mobile_li{
        display: block;
    }

    .dropdown-btn_mbl{
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
    }

    .dropdown-btn{
        display: none;
    }
    .nav-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: -60px;
        width: 100%;
        background-color: white;
        z-index: 9999;
    }

    .nav-links.active {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-bottom: 10px;
    }

    .nav-links ul {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .nav-links li {
        margin: 10px 0;
    }

    .menu-icon {
        display: block;
    }

    .get-formatter-btn{
        display: none;
    }

    .get-formatter-container {
        display:none;
    }
}
