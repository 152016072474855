/* PricingPlan.css */

.pricing-section {
   /* background-image: url('/path-to-your-image.png');  Background image */
    background-size: cover;
    background-position: center;
    padding: 60px 20px;
    text-align: center;
  }
  
  .header {
    font-size: 2.5rem;
    color: #ffffff;
  }
  
  .header .highlight {
    color: #0099ff;
  }
  
  .toggle-buttons {
    margin: 30px 0;
    display: inline-block;
  }
  
  /* .toggle-button {
    background-color: #ffffff;
    border: none;
    color: #0099ff;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  } */
  
  /* .toggle-button.active {
    background-color: #0099ff;
    color: white;
  } */
  
  .package-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }
  
  .package-card {
    background-color: white;
    color: black;
    width: 220px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .package-card:hover {
    transform: scale(1.05);
  }
  
  .package-card.most-popular {
    border: 2px solid #0099ff;
    position: relative;
  }
  
  .package-card.most-popular::before {
    content: "Most Popular";
    background-color: #0099ff;
    color: white;
    position: absolute;
    top: -15px;
    right: -15px;
    padding: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
  }
  
  .package-details {
    margin-bottom: 20px;
  }
  
  .package-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .package-price {
    display: block;
    background-color: #f0f8ff;
    color: #0099ff;
    padding: 8px;
    font-size: 1.4rem;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .package-content1 {
    color: #0099ff;
    margin: 10px 0;
  }
  
  .package-content2 {
    color: #666;
  }
  
  .install-button {
    background-color: #0099ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3 ease-in-out;
  }
  
  .install-button:hover {
    background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .package-container {
      flex-direction: column;
      align-items: center;
    }
  
    .package-card {
      width: 100%;
      max-width: 350px;
    }
  }
  