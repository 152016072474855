/* Main Title */
.main-title {
    text-align: center;
    margin-bottom: 0px;
  }
  
  .main-title h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #000; /* Dark blue */
  }
  
  .main-title .highlight {
    color: #6ABAE2; /* Light blue for "Use" */
  }

  .how-to-use-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2faff;
    margin: 0 auto;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
  }


  
  .steps-container, .video-container {
    flex: 1 1 50%; /* Each takes 50% of the container's width */
    max-width: 50%; /* Ensures they don’t exceed 50% width */
    padding: 0 15px; /* Optional padding for spacing */
    box-sizing: border-box; /* Keeps padding within the 50% width */
  }
  

  .steps-container::-webkit-scrollbar,
.video-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
  

.step-description{
  color: var(--colorblack, #001b34);
  text-align: center;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 18px;
  line-height: 100%;
  font-weight: 500;
}
/*
  color: var(--colorblack, #001b34);
  text-align: left;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 30px;
  line-height: 100%;
  font-weight: 500;
*/


    /* Section container */
    .how-to-use-dev {
      margin-top: -30px;
      padding-top: 40px;
      padding-bottom: 50px;
      background-color: #f2faff;
    }
  


  .step-label_sub{
    background-color: #04587A;
    color: #ffffff;
    padding: 7px;
    margin-right: 6px;
    border-radius: 12px;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.15em;
    font-weight: 500;
    
  }
  
  .steps-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .steps-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    width: 100%;
  }
  
  .step-item:hover {
    transform: translateY(-5px);
    background-color: #e6f7ff; /* Light blue on hover */
  }
  
  .step-item.active {
    border-left: 5px solid #007bff; /* Add a blue border when active */
  }
  
  .step-label {
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .step-item img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .step-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .step-item p {
    font-size: 14px;
    color: #666;
  }
  

  
  iframe {
    border-radius: 10px;
    width: 100%;
    height: 315px;
    border: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .how-to-use-section {
      flex-direction: column-reverse;
    }


  .video-container, .steps-container {
    width: 100%; /* Full width on mobile */
    max-width: 100%; /* Ensure they don’t exceed 100% width */
    padding: 0 15px; /* Optional padding for consistent spacing */
    box-sizing: border-box;
  }
  
    .video-container {
      margin-top: 50px;
      padding: 0px;
      width: 100%; 
    }
  
    .steps-container {
      width: 100%;
      padding: 0; /* Ensures steps container is also full-width */
    }

    .step-label {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      color: #007bff;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      margin: 0 auto;
    }

    .step-description{
      color:  #001b34;
      text-align: left;
      font-family: "HelveticaNeue-Medium", sans-serif;
      font-size: 20px;
      line-height: 100%;
      font-weight: bold;
      margin-top: 5px;
    }
    #mobile_disp_none{
      display: none;
    }

    .hiddenFrame{
      display: none;
    }
  }
  