/* Workflow.css */

.workflow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px 10px 30px;
    gap: 60px;
    margin-top: 70px;
    background-color: #ffffff; /* Optional light background color */
  }

  .header-btns-automate-formatting{
    padding: 0px;
  }

  .header-btns-automate-formatting_title{
    padding: 12px;
  }


  .workflow-container hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  }
  
  .workflow-content {
    width: 55%;
  }
  
  .workflow-title_P {
    font-size: 2.5rem;
    font-weight: bold;
    /* color: #000; */
    color: #6ABAE2; 
    text-align: left !important;
    
  }
  
  .workflow-title_P span {
    color: #000000; /* Optional color for 'Functionality' */
  }

  .image-wrapper {
    position: relative;
    width: 55%;
    /* height: 300px; */
  }
  
  .image-back img {
    margin-left: 40px;
    width: 96%;
    height: 110%;
    z-index: 1;
  }
  
  .image-front img {
    position: absolute;
    top: 8px;
    right: 0;
    width: 100%;;
    z-index: 2;
  }

  .workflow-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(0, 27, 52, 0.75);
    margin-bottom: 30px;
  }
  
  .workflow-description_txt{
    color: rgba(0, 27, 52, 0.75);
    text-align: left;
    font-family: var(
      --paragraph-desktop-font-family,
      "HelveticaNeue-Regular",
      sans-serif
    );
    font-size: 18px;
    font-weight: 400;
  }

  .workflow-features h3 {
    color: var(--hubxpert-black, #001b34);
    text-align: left;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 30px;
    line-height: 100%;
    font-weight: bold;
    margin: 0px;
  }
/*   
  .workflow-features p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
  }
   */
  .workflow-image {
    position: relative;
    display: inline-block; /* This will allow the container to wrap around the image */
  
    background-size: cover; /* Cover the entire area */
    border-radius: 8px;
  }
  
  .workflow-image img {
    display: block; /* Ensure the image takes full width */
    width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: Rounded corners */
  }
  
  /* Responsive for mobile devices */

  @media (max-width: 600px) {
    .image-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 768px) {

    .workflow-features h3{
      display: flex;
      margin: 0 auto;
      font-size: 19px;
      justify-content: center;
    }

    .workflow-features_h3{
      display: flex;
      margin: 0 auto;
      font-size: 19px;
      justify-content: center;
    }
    .workflow-title_P{
      display: flex;
      margin: 0 auto;
      justify-content: center;
      font-size: 24px;
    }

    .header-btns-automate-formatting{
      margin-bottom: 20px;
      font-size: 16px;
    }

    .image-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .workflow-container {
      flex-direction: column-reverse;
      text-align: center;
      padding: 15px;
      justify-content: center;
    }
  
    .workflow-content {
      width: 100%;
    }
  
    .workflow-image {
      width: 100%;
      margin-top: 20px;
    }
  
    .workflow-image img {
      width: 90%; /* Adjust the image width for mobile */
    }


  }



