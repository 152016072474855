.functionality-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.functionality-content {
    max-width: 800px; 
    margin: 0 auto; 
    font-size: 1.1em;
}

.functionality-section h2 {
    font-size: 3em;
    margin-bottom: 20px;
    color: #333;
}

.functionality-section p {
    margin-bottom: 30px;
    opacity: 0.7;
    text-align: left;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .functionality-section {
        padding: 15px;
    }

    .functionality-section h1 {
        font-size: 2.5em; 
    }

    .functionality-section h2 {
        font-size: 2.1em; 
    }

    .functionality-section p {
        font-size: 1.2em;
    }

   
}

@media (max-width: 480px) {
    .functionality-section {
        padding: 10px;
    }

    .functionality-section h1 {
        font-size: 1.8em; 
    }

    .functionality-section p {
        font-size: 1em;
    }

  
}
