/* src/HeroSection.css */
.hero-section {
    background-image: url("./HeroSection.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 850px;
    width: 100%;
}

.heading_hero_main{
    color:  #04587a;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 72px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.hero-content {
    text-align: center;
    z-index: 1;
    margin-top: 80px;
}

.book-a-meeting{
    padding: 12px 48px;
    border-color:  #64bae2 !important;
    color: #64bae2 !important;
    background:rgba(255, 255, 255, 0.5) !important;
}

.hero-content h1 {
    font-size: 3.8em;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 3px;
    color: #1D587A; 
}

.hero-content p{
    color: rgba(0, 27, 52, 0.75);
    text-align: center;
    font-family: "HelveticaNeue-Regular", sans-serif;
    font-size: 20px;
    line-height: 140%;
    font-weight: 400;
    position: relative;
    align-self: stretch;
}

.hero-content h2 {
    font-size: 2.5em;
}

.hero-content p {
    font-size: 1.3em;
}

.buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 50px;
}

.hero-btn2 {
    padding: 15px 51px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    min-width: 250px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family:
    "HelveticaNeue-Medium",
    sans-serif;
}

.btn {
    font-size: 1em;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.profile-link{
    color: bisque;
}

.hero-btn1 {
    color: #04587a;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 24px;
    line-height: 100%;
    font-weight: bold;
}

/* .hero-btn1:hover {
    background-color: #5893a8;
    color: #ffffff;
} */


/* .hero-btn1:hover {
    background-color: #5893a8; 
    color: #ffffff;
} */

.hero-btn2 {
    border: 1px solid #6ABAE2;
    border-radius: 5px;
    background-color:#6ABAE2;
    color: #ffffff;
    padding: 12px 34px;
    transition: 0.3s ease-in-out;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.hero-btn2:hover, .book-a-meeting:hover {
    background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%) !important;
    color: #ffffff !important;
}

.doc-btn {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.doc-btn:hover {
    background-color: white;
    color: black;
}

.wave-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px; /* Adjust this height as needed */
    overflow: hidden;
    line-height: 0;
}

.waveCSS1{
    transform:rotate(180deg); 
    transition: 0.3s;
}

.waveCSS2{
    transform:translate(0, 0px); 
    opacity:1;
}

/* Mobile Responsive */
@media (max-width: 768px) {

    .book-a-meeting{
        padding: 14px 95px !important;
    }

    .flex_two_btn{
        display: flex;
        flex-flow: column;
    }

    .hero-content{
        padding: 5px;
        margin-top: 45px;
    }

    .hero-btn1{
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 300px;
    }

    .heading_hero_main, .txt_hero_section{
        margin-top: 15px;
        margin-bottom: 5px;

    }

    .txt_hero_section span{
        font-size: 11px;
    }

    .hero-section h1 {
        font-size: 2.5em; 
      
    }

    .hero-section p {
        font-size: 1.2em; 
      
    }

    .hero-section .cta-button {
        padding: 12px 20px;
        font-size: 1em; 
    }
    .hero-btn2{
        padding: 15px 80px;
        min-width: 350px;
        font-size: 18px;
    }
    .hero-section {
        background-image: url("./HeroSectionMobile.png");

    }
}

@media (max-width: 480px) {
    /* src/HeroSection.css */

    .buttons{
        margin: 0px;
    }

    .hero-section h1 {
        font-size: 1.8em; 
    }

    .hero-section h2 {
        font-size: 1.56em; 

    }

    .hero-content p{
        font-size: 16px;
    }

    .hero-section .cta-button {
        padding: 10px 15px; 
        font-size: 0.9em; 
    }

    .hero-btn2{
        min-width: 350px;
    }
}



@media (min-width: 1024px) {
    .hero-btn2 {
        padding: 17px 60px; /* Increase padding for a larger button */
        font-size: 24px; /* Increase font size */
        min-width: 300px; /* Increase minimum width */
    }
}