.pricing-section {
  background-image: url('./backend.png');
  padding: 50px 20px;
  min-height: 60vh;
  text-align: center;
}

.price-amount {
  color:  #64bae2;
  text-align: right;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 48px;
  line-height: 100%;
  font-weight: 500;
  position: relative;
  width: 94px;
  height: 38px;
}

.custom_pricing{
  color: #64bae2;
  text-align: center;
  font-family: "HelveticaNeue-Medium", sans-serif;
  font-size: 30px;
  line-height: 100%;
  font-weight: 500;
}

.choose_plan{
  background: rgba(100, 186, 226, 0.25) !important;
  color: #64bae2 !important;
}

.dollar {
  color: #000;
  font-size: 20px;
  margin-bottom: 100px;
  font-weight: bold;
  margin-right: 7px;
}

.popular-badge {
  background-color: #04587A;
  color: white;
  padding: 10px 15px;
  font-size: .9rem;
  border-radius: 200px;
  position: absolute; /* Ensures the badge is positioned relative to the card */
  top: -15px; /* Moves it upwards above the card */
  left: 50%; /* Centers it horizontally */
  transform: translateX(-50%); /* Offsets by 50% of its own width to truly center it */
  width: 120px;
  display: flex;
  justify-content: center;
  z-index: 1; /* Ensures it stays above the card */
}


.pricing-main:hover {
  transform: scale(1.05);
}

.highlight {
  color: #64BAE2; /* Blue highlight for "Pricing" */
}

.pricing-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line */
  justify-content: center; /* Centers items as a whole */
  gap: 35px; /* Space between cards */
}

.pricing-main {
  flex: 220px; /* Cards have a minimum width of 200px */
  max-width: calc(20% - 25px); /* Max width is 20% (for 5 columns) minus the gap */
}

.pricing-main {
  position: relative;
  background-color: white;
  padding: 12px 12px;
  border-radius: 15px;
  text-align: center;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
}

.plan-header {
  position: relative;
  margin-bottom: 1px;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #04587A;
}

.plan-pricing {
  font-size: 2rem;
  color: #0099ff;
  margin-bottom: 0px;
}

.price-duration {
  font-size: 0.9rem;
  color: #666;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  margin-bottom: 20px;
}

.plan-features li {
  color: #200335;
  text-align: left;
  font-family: "HelveticaNeue-Regular", sans-serif;
  font-size: 20px;
  line-height: 100%;
  font-weight: 400;
}

hr{
  margin: 15px 0 0 0;
  color: #001B3440;
}

.plan-button {
  font-family:
  "HelveticaNeue-Medium",
  sans-serif;
  background-color:#64BAE2;
  color: white;
  font-weight: 600;
  border: none;
  font-size: 19px;
  width: 100%;
  padding: 15px 51px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3 ease-in-out;
}

.plan-button:hover {
  background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);

}

.plan-button.chosen {
  background-color: #00c851;
}

.plan-button.chosen:hover {
  background-color: #009a44;
}

/* Ensure all pricing cards are the same height */
.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pricing-container {
    grid-template-columns: 1fr;
  }

  .pricing-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

.title-div {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.title-div-1 {
  flex: 1;
  text-align: left;
}

.toggle-buttons {
  background-color: #FFFFFF1A;
  width: max-content;
  padding: 4px;
  border-radius: 8px;
  display: none;
}
.toggle-button{
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
  background-color: #00000000;
  border: none;
}

.toggle-button.active {
  background-color: #04587A;
  ;
}

.proxy_div{
  flex: 1;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.pricing-title {
  font-size: 2rem;
  color: white;
}


@media (max-width: 768px) {

  .proxy_div{
    margin-bottom: 30px;
  }
  
  .title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  .title-div-1 {
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  
  .pricing-main {
    max-width: 100%; /* On smaller screens, make cards full width */
  }
}

