.hubspotForm_main{
    padding: 20px 200px;
    align-self: center;
}
.hubspotForm_main p{
    text-align: center;
    padding-bottom: 10px;
}
.workflow-title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    text-align: center;
}  
.workflow-title span {
    color: #6ABAE2; /* Optional color for 'Functionality' */
}


/* Responsive for mobile devices */
@media (max-width: 768px) {
    .hubspotForm_main{
        padding: 15px;
        padding-top: 40px;
        margin: 0;
        min-height: 115vh;
    }
    
    .hubspotForm_main h1{
        text-align: center;
    }
}
