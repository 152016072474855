.footer {
  background-image: url("https://26291308.fs1.hubspotusercontent-eu1.net/hubfs/26291308/HS%20For%20BD%20Landing%20Page/footer-bg.webp");
  padding: 60px 10px 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-section {
  color: #002D57;
  flex: 1;
  padding: 10px;
  text-align: left;
}

.footer-section:nth-child(1), .footer-section:nth-child(2) {
  flex-basis: 30%;
}

.footer-section:nth-child(3), .footer-section:nth-child(4) {
  flex-basis: 20%;
}

.logo img {
  width: 150px;
}

.content {
  font-family: 'Lexend Deca Helvetica Neue', sans-serif;
  font-size: 18px;
  margin-top: 20px;
  line-height: 29px;
  color: #002D57;
  text-align: left;
  font-weight:400;
}

.upwork-section {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.upwork-logo {
  width: 120px;
}

.upwork-profile-btn {
  background-color: #6FDA44;
  padding: 10px 20px;
  border-radius: 20px;
  color: #000000;
  font-weight: bold;
  border: none;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;

}

.gold-partner-txt{
  color: #002D57;
  font-size: 30px;
  font-weight: 800;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

.upwork-profile-btn1 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}


.upwork-profile-btn:hover{
  color: white;
  background-color: #000;
  transform: scale(1.1); 
}

.gold-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gold-logo {
  width: 190px;
}

.white-label-btn {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  color: #000;
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.white-label-btn:hover{
  transform: scale(1.1);
  color: white;
  background-color: #E95532;
}
.useful-links {
  list-style-type: none;
  padding: 0;
}

.footer-section h2{
  text-align: left;
}
.GF-Blue{
  width: 180px;
}

.useful-links li {
  text-align: start;
  margin: 20px 0;
  font-weight: 500;
  margin-bottom: 10px;
}

.useful-links a {
  color: #002D57;
  text-decoration: none;
  font-weight: 400
}

.social-media {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.social-media a {
  height: 15px;
  width: 15px;
  margin: 0 10px;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  color: #002D57;
}

.social-media a:hover {

  background-color: #F2740E;
  color: #ffffff;
}


.social-media img {
  width: 30px;
}

.goodfirms-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.goodfirms-section img {
  width: 80px;
  margin-right: 10px;
}

.footer-copyright {
  text-align: center;
  font-size: 16.6px;
  font-weight: 600;
  color: #002D57;
}

.footer-copyright-hr{
  color: #F2740E;
  margin-bottom: 30px;
  background-color: #F2740E;
}

.icon {
  font-size: 1rem;
  color: #002D57;
  background-color: #ffffff;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .5s;
}

.verified{
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-flow: row;
  gap: 10px;
}

.verified p{
  font-weight: 800;
  color: #002D57;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column; /* Stack sections vertically */
    align-items: center;
  }

  .footer-section {
    flex-basis: 100%;
    margin-bottom: 20px; /* Add some space between the sections */
  }

  .footer-section:nth-child(1), 
  .footer-section:nth-child(2),
  .footer-section:nth-child(3), 
  .footer-section:nth-child(4) {
    flex-basis: 100%;
  }
}