/* FAQ.css */

.faq-section {
    padding: 10px 20px;
    padding-bottom: 100px;
    margin-top: 35px;
    margin-bottom: 30px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .faq-header {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #000;
  }
  
  .faq-header .highlight {
    color: #6ABAE2;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faq-question span{
   color: #001b34;
   font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 20px;
    line-height: 100%;
    font-weight: 700;
  }
  
  .faq-answer {
    padding: 15px 0;
  }

  .faq-answer p{
    color: rgba(0, 27, 52, 0.75);
    font-family: "HelveticaNeue-Regular", sans-serif;
    font-size: 20px;
    align-self: stretch;
    font-size: 20px;
  }
  
  .toggle-icon {
    font-size: 1.5rem;
    color: #0099ff;
  }
  
  .faq-question:hover {
    color: #0099ff;
  }
  
  .faq-question:hover .toggle-icon {
    color: #003366;
  }
  
  /* Responsive for Mobile */
  @media (max-width: 768px) {
    .faq-header {
      font-size: 2rem;
    }
  
    .faq-question {
      font-size: 1.1rem;
    }
  
    .faq-answer {
      font-size: 0.95rem;
    }
  }
  