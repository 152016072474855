.preferred-choice-container {
  text-align: center;
  padding: 40px;
  padding-bottom: 100px;
  background-color: #ffffff;
  max-width: 1400px; /* Adjust based on your layout needs */
  margin: 0 auto;
}

.preferred-choice-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #000;
}

.preferred-choice-text .highlight {
  color: #6ABAE2; /* Highlight the important text */
}

.company-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center; /* Centers the items */
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  max-width: 1200px; /* Optional: limits width on larger screens */
}

.company-logo {
  display: flex;
  max-width: 150px;
  align-items: center;
  justify-content: center;
}


.company-logo img {
  max-width: 100%; /* Keeps logos responsive */
  height: auto;
}


/* CTA Button */
.cta-container {
  margin-top: 20px;
}

.cta-button {
  background-color: #64BAE2;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3 ease-in-out;
}

.cta-button:hover {
  background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);
}

/* Responsive Design for Mobile */
@media screen and (max-width: 768px) {
  .preferred-choice-container {
      padding: 15px;
      /* padding-bottom: 100px; */
  }

  .preferred-choice-text h2 {
      font-size: 2rem;
  }
  .company-logo img {
    width: auto;
    height: auto;
    margin: 20px;
    padding: 10px;
}
  .cta-button {
      font-size: 1rem;
      padding: 12px 25px;
  }

  .company-logos{
    gap: 0px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 3fr));
  } 
}

@media screen and (max-width: 480px) {
  .preferred-choice-text h2 {
      font-size: 1.8rem;
  }

  .company-logo img {
      width: auto;
      height: auto;
      margin: 20px;
  }

  .cta-button {
      font-size: 0.9rem;
      padding: 10px 20px;
  }
}
