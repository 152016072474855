/* QualityCheck.css */

.quality-check-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    gap: 30px;
    margin-top: 70px;
    background-color: #ffffff; /* Optional light background color */
  }

  .quality-check-container hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    }
  
  .quality-check-image {
    width: 65%;
  }
  
  .quality-check-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .quality-check-content {
    width: 50%;
    padding-left: 20px;
  }
  
  .quality-check-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .quality-check-title span {
    color: #6ABAE2; /* Optional highlight color */
  }
  
  .quality-check-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666666;
    margin-bottom: 30px;
  }
  
  .quality-check-features h3 {
    color:#001b34;
    text-align: left;
    font-family: "HelveticaNeue-Medium", sans-serif;
    font-size: 30px;
    line-height: 100%;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
  }
  
  /* .quality-check-features p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
  } */


  .image-wrapper_qc {
    position: relative;
    width: 55%;
    height: 500px;
  }
  
  .image-back_qc img {
    margin-right: 80px;
    width: 97%;
    height: 100%;
    z-index: 1;
  }
  
  .image-front_qc img {
    position: absolute;
    top: 10px;
    right: 0;
    left: 16px;
    width: 100%;
    z-index: 2;
  }
  
  
  
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .quality-check-container {
      flex-direction: column;
      padding: 15px;
      text-align: center;
    }

    .title_quality_check_h3{
      display: flex;
      margin: 0 auto;
      justify-content: center;
    }
  
    .quality-check-image, .quality-check-content {
      width: 100%; /* Full width on mobile */
    }
  
    .quality-check-image img {
      width: 90%; /* Adjust image size on mobile */
      margin: 0 auto;
    }
  
    .quality-check-content {
      padding-left: 0;
      margin-top: 20px;
    }
  
    .quality-check-title {  
      font-size: 24px;/* Adjust title font size for mobile */
    }
  
    .quality-check-features h3 {
      font-size: 1.2rem; /* Adjust heading size for mobile */
    }
  
    .quality-check-features p {
      font-size: 0.95rem; /* Adjust paragraph size for mobile */
    }

    .image-wrapper_qc {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  
