/* UserProfile.css */

.user-profile-section {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f5fafd;
  }
  
  .profile-card {
    background-color: #eaf3f7;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .profile-title {
    font-size: 1.8rem;
    color: #003366;
  }
  
  .profile-title .highlight {
    color: #0099ff;
  }
  
  .edit-icon img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .profile-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .profile-item.split {
    justify-content: space-between;
    gap: 50px;
  }
  
  .item-title {
    font-size: 1rem;
    color: #003366;
    font-weight: 500;
  }
  
  .item-value {
    font-size: 1rem;
    color: #666666;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f0f5fc;
  }
  /* Submit Button */
.update-button {
  width: 100%;
  padding: 15px;
  background-color: #0099ff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.update-button:hover {
  background: linear-gradient(180deg, #04587A 0%, #64BAE2 100%);
}
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .profile-card {
      padding: 20px;
      width: 100%;
    }
  
    .profile-title {
      font-size: 1.5rem;
    }
  
    .item-title, .item-value {
      font-size: 0.9rem;
    }
  
    .profile-item.split {
      flex-direction: column;
      gap: 10px;
    }
  }

  /* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .form-group input {
    font-size: 0.9rem;
  }

  .update-button {
    font-size: 0.9rem;
  }
}
  